//############################ JS ############################

require('jquery/dist/jquery.js');
// Bootstrap
require('bootstrap/dist/js/bootstrap.bundle.js');

// // viewport-checker
require('./js/viewport-checker.js');
require('inputmask/bundle.js');
require('jquery-creditcardvalidator/jquery.creditCardValidator.js')
// Main js
require('./js/vanilla-tilt.min.js');
require('./js/marquee.js');
require('./js/slick.min.js');
require('./js/component.js');

//############################ CSS ############################

// main
require('./sass/main.scss'); 