// import bootstrap from "bootstrap/dist/js/bootstrap"

$(document).ready(function () {
  function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      ||
      (navigator.userAgent.includes("Mac"))
  }

  $(window).scroll(function () {
    return $('.navbar').toggleClass("shadow-sm", $(window).scrollTop() > 0);
  });


  $('.back-btn').click(function () {
    $(this).parents('.nav-item').find('.nav-link').trigger('click')
  })

  if ($('div').hasClass('scrollspy')) {

/*        var scrollSpy = new bootstrap.ScrollSpy(document.body, {
          target: '#navbar-example',
          offset: $(window).height() / 2,
          tabindex: 0
        })*/

        var scrollSpy = $('body').scrollspy({ target: '#navbar-example', target: '#navbar-example',
          offset: $(window).height() / 2,
          tabindex: 0 })


    const offsetItem1 = $('#item1').offset().top;

    $(window).scroll(function () {

      if ($(window).scrollTop() < offsetItem1) {
        $('#navbar-example .item[href="#item1"]').addClass('active')
      }

      $('.scrollspy-dots li').removeClass('active')
      $('.scrollspy-dots li[data-href="' + $('#navbar-example .item.active').attr('href') + '"]').addClass('active')
    })

  }


  if ($('div').hasClass('pricing') || $('div').hasClass('packages')) {

    const pricingOffset = $('#flagShowNavPricing').offset().top - 300;

    $(window).scroll(function () {
      $('#pricing-nav').hide();

      // console.log('pricingOffset', pricingOffset)
      // console.log('pricing', $(window).scrollTop())

      if ($(window).scrollTop() > pricingOffset && ($('#pricing').height() - 300) > $(window).scrollTop()) {
        $('#pricing-nav').show();
      }

    })
  }




  // console.log('ios', iOS());

/*  if (iOS()) {
    $('.items .item, .bg-yellow-dark, .bg-pink, .bg-indigo').addClass('isIos');
    $('.sticky-scroll-top').click(function (e) {
      e.preventDefault();
      var destination = $(".plan-sticky").offset().top - 0;
      $("body,html").animate({
        scrollTop: destination
      }, 1000);
    });
  } else {
    $('.sticky-scroll-top').click(function (e) {
      e.preventDefault();
      var destination = $(".plan-sticky").offset().top - 0;
      $("body,html").animate({
        scrollTop: destination
      }, 0);
    });
  }*/

  /*toggle plan*/

  $('.plan-toggle').click(function (e) {
    e.preventDefault();
    $(this).parent().find('.dropdown-menu').toggleClass('show');
  })

  $('.plan-toggle .dropdown-item').click(function (e) {
    e.preventDefault();

    $('#plan').val($(this).data('value'));
    $(this).parents('.plan-toggle').find('.plan-text').html($(this).find('h6').html())


  })


  var myCarousel = document.getElementById('carouselExampleIndicators')

  if (myCarousel) {
    myCarousel.addEventListener('slid.bs.carousel', function () {

      $('.carousel-title .fs-8').removeClass('d-block').addClass('d-none');

      $('[data-slide="' + $('.carousel-item.active').data('index') + '"]').addClass('d-block').removeClass('d-none');

      $('#cBtn [data-bs-target="#carouselExampleIndicators"]').removeClass('btn-dark').addClass('bg-gray300');

      $('[data-bs-slide-to="' + $('.carousel-item.active').data('index') + '"]').addClass('btn-dark').removeClass('bg-gray300');

      console.log($('.carousel-item.active').data('index'))
      $('input[name="package"]').val($('.carousel-item.active').data('package'));
    })
  }






  $('.pricing-item').click(function () {

    $('.pricing-item, .item-bg').removeClass('active');
    $(this).addClass('active');
    $('input[name="package"]').val($(this).data('package'));
    $('.item-bg[data-package=' + $(this).data('package') + ']').addClass('active');
    $('.pricing-item[data-package=' + $(this).data('package') + ']').addClass('active')

    $('[href="#signup"]').attr('data-plan-title', $(this).data('package'))

  })


  $(window).resize(function () {
    if ($('.carousel-item.active').data('package')) {
      $('input[name="package"]').val($('.carousel-item.active').data('package'));
    } else {
      $('input[name="package"]').val($('.pricing-item.active').data('package'));
    }
    if ($(window).width() > 1200) {
      $('.item-bg[data-package=' + $('.pricing-item.active').data('package') + ']').addClass('active')

      // $(".marquee-scroll").make_carousel();
      // $(".hide-scroll").make_carousel();
      $('.fade-in, .fade-left, .fade-right, .zoom-in').removeClass('is-mob')

      $('.fade-in:not(.is-mob)').addClass("hidden_animation").viewportChecker({
        classToAdd: 'visible animated fadeInUp', // Class to add to the elements when they are visible
        classToRemove: 'hidden_animation',
        offset: '15%'
      }); 

      $('.fade-left:not(.is-mob)').addClass("hidden_animation").viewportChecker({
        classToAdd: 'visible animated fadeInLeft', // Class to add to the elements when they are visible
        classToRemove: 'hidden_animation',
        offset: '15%'
      }); 

      $('.fade-right:not(.is-mob)').addClass("hidden_animation").viewportChecker({
        classToAdd: 'visible animated fadeInRight', // Class to add to the elements when they are visible
        classToRemove: 'hidden_animation',
        offset: '15%'
      }); 

      $('.zoom-in:not(.is-mob)').addClass("hidden_animation").viewportChecker({
        classToAdd: 'visible animated zoomIn', // Class to add to the elements when they are visible
        classToRemove: 'hidden_animation',
        offset: '15%'
      }); 
    }else{
      $('.fade-in, .fade-left, .fade-right, .zoom-in').addClass('is-mob').removeClass('hidden_animation')
    }
  })

  if ($(window).width() > 1200) {
    $('.fade-in, .fade-left, .fade-right, .zoom-in').removeClass('is-mob')

    $('.fade-in:not(.is-mob)').addClass("hidden_animation").viewportChecker({
        classToAdd: 'visible animated fadeInUp', // Class to add to the elements when they are visible
        classToRemove: 'hidden_animation',
        offset: '15%'
      }); 

      $('.fade-left:not(.is-mob)').addClass("hidden_animation").viewportChecker({
        classToAdd: 'visible animated fadeInLeft', // Class to add to the elements when they are visible
        classToRemove: 'hidden_animation',
        offset: '15%'
      }); 

      $('.fade-right:not(.is-mob)').addClass("hidden_animation").viewportChecker({
        classToAdd: 'visible animated fadeInRight', // Class to add to the elements when they are visible
        classToRemove: 'hidden_animation',
        offset: '15%'
      }); 

      $('.zoom-in:not(.is-mob)').addClass("hidden_animation").viewportChecker({
        classToAdd: 'visible animated zoomIn', // Class to add to the elements when they are visible
        classToRemove: 'hidden_animation',
        offset: '15%'
      }); 

  }else{
    $('.fade-in, .fade-left, .fade-right, .zoom-in').addClass('is-mob').removeClass('hidden_animation')
  }

      $('.fade-in-up').addClass("hidden_animation").viewportChecker({
        classToAdd: 'visible animated fadeInUp', // Class to add to the elements when they are visible
        classToRemove: 'hidden_animation',
        offset: '15%'
      }); 

  $('.coupon-reset').click(function () {
    var $this = this;
    $.ajax({
      url: "/signup/step-3",
      method: "POST",
      data: { reset: true, code: $('#code-input').val() },
    })
      .done(function (html) {
        $($this).addClass('d-none');
        $($this).parents('form').find('.coupon-submit').removeClass('d-none')
        if(html.paypal_link){
          $('#paypal-btn').attr('href', html.paypal_link);
        }
        console.log(html);
        $('input[name="code"]').removeAttr('disabled')
        $('#coupon-price').text($('#full-price').text())
        $('#full-price').addClass('d-none')
        $('#reset_coupon_inp').val(1);
      });
  })

  $('a[href="#signup"]').on('click', function (e) {
    var plan = $(this).data('plan-title');
    var step = $('#step_signup').val();
    var dashboard = $('#goto-dashboard').attr('href');
    if (plan) {
      $('#plan_signup').val(plan);
    }
    if (step == 1) {
      window.location.href = '/signup/step-1';
    } else if (step == 2) {
      window.location.href = '/signup/step-2';
    }
    else if (step == 3) {
      window.location.href = '/signup/step-3';
    }
    else if (step == 4) {
      window.location.href = '/welcome';
    } else if (dashboard) {
      window.location.href = dashboard;
    }
  })

  $('a[href="#coupon-content"]').click(function (e) {
    e.preventDefault();
    $(this).removeClass('d-block').addClass('d-none');
    $($(this).attr('href')).removeClass('d-none')
  })

  $('#coupon-content .coupon-submit').click(function (e) {
    var $this = this;
    e.preventDefault();
    e.stopPropagation();
    $.ajax({
      url: "/signup/step-3",
      method: "POST",
      data: { code: $('#code-input').val() },
    })
      .done(function (html) {
        if (html.type != 'success') {
          $('#coupon-content form').addClass('was-validated');
          $('#code-input').addClass('is-invalid');
        } else {
          $($this).removeClass('d-block').addClass('d-none');
          $($($this).attr('href')).removeClass('d-none');
          $('a[href="#coupon-content"]').addClass('d-none');
          $('#code-input').attr('disabled','disabled');
          $('.coupon-reset').removeClass('d-none');
          $('#coupon-content form').removeClass('was-validated');
          $('#code-input').removeClass('is-invalid');
          if(html.price){
            $('#full-price').removeClass('d-none');
            $('#coupon-price').text('$'+html.price);
          }
          if(html.paypal_link){
            $('#paypal-btn').attr('href', html.paypal_link);
          }
        }
      });
  })
  $('#code-input').on('keyup', function(){
    if($(this).hasClass('is-invalid')) {
      $('#coupon-content form').removeClass('was-validated');
      $('#code-input').removeClass('is-invalid');
    }
  })


  $('.plan-sticky .pricing-item').click(function () {
    $('.content-item').removeClass('active');
    $('#' + $(this).data('package')).addClass('active');
  })
  $('.signup-step-1 input').on('keyup', function () {
    if ($(this).hasClass('is-invalid')) {
      $(this).removeClass('is-invalid')
    }
  })



  // Вешаем на прикосновение функцию handleTouchStart
  document.addEventListener('touchstart', handleTouchStart, false);
  // А на движение пальцем по экрану - handleTouchMove      
  document.addEventListener('touchmove', handleTouchMove, false);

  var xDown = null;
  var yDown = null;

  function handleTouchStart(evt) {
    xDown = evt.touches[0].clientX;
    yDown = evt.touches[0].clientY;
  };

  function handleTouchMove(evt) {
    if (!xDown || !yDown) {
      return;
    }

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;
    // немного поясню здесь. Тут берутся модули движения по оси абсцисс и ординат (почему модули? потому что если движение сделано влево или вниз, то его показатель будет отрицательным) и сравнивается, чего было больше: движения по абсциссам или ординатам. Нужно это для того, чтобы, если пользователь провел вправо, но немного наискосок вниз, сработал именно коллбэк для движения вправо, а ни как-то иначе.
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        if ($('.pricing-item.active').next().length > 0 && $('.pricing-item.active').next().hasClass('pricing-item')) {
          $('.pricing-item.active').next().trigger('click')
        } else {
          $($('.pricing-item')[0]).trigger('click')
        }

        console.log('left', $('.pricing-item.active').next().length)
      } else {
        if ($('.pricing-item.active').prev().length > 0) {
          $('.pricing-item.active').prev().trigger('click')
        } else {
          $($('.pricing-item')[$('.pricing-item').length - 1]).trigger('click')
        }

        //        console.log('right', $('.pricing-item.active').next().length)
        /* right swipe */
      }
    } else { // Это вам, в общем-то, не надо, вы ведь только влево-вправо собираетесь двигать
      if (yDiff > 0) {
        /* up swipe */
      } else {
        /* down swipe */
      }
    }
    /* reset values */
    xDown = null;
    yDown = null;
  };


  var signup = document.getElementById('signup')
  if (signup) {
    signup.addEventListener('shown.bs.modal', function (event) {
      // do something...
      $('.signup-form').addClass('active');
    })
    signup.addEventListener('hidden.bs.modal', function (event) {
      // do something...
      $('.signup-form').removeClass('active');
    })
  }


  $('.popular-item').each(function(index){
    if (index > 3) {
      $(this).addClass('d-none d-sm-block');
    }
  })

  $('a[href="#moreFeatures"]').click(function (e) {
    e.preventDefault();
    $(this).parents('.row').find('.popular-item.d-none').removeClass('d-none');
    $(this).parent().hide();
  })



  VanillaTilt.init(document.querySelectorAll(".parallax-container"), {
    max: 15,
    speed: 300,
    easing: "cubic-bezier(.03,.98,.52,.99)",
    scale: 1.0,
  });



  $mq =  $('.marquee').marquee({
    duration: $('.marquee li').length * 6000,
    startVisible: true,
    gap: 0,
    direction: 'left',
    duplicated: true,
    pauseOnHover:true,
    allowCss3Support:true,
    css3easing:'linear',

  });

  // $('.marquee li').hover(function () {
  //   $mq.marquee('pause');
  // },
  // function () {
  //   $mq.marquee('toggle');
  // })

  // $(".marquee-scroll").make_carousel();
  // $(".hide-scroll").make_carousel();


  $('.even-slider').slick({
    dots: true,
    infinite: true,
    arrows: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    centerMode: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });

  $('.pxtools-carousel').slick({
    dots: false,
    infinite: false,
    prevArrow: $('.prev'),
    nextArrow: $('.next'),
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    rows: 1,

    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
           dots: true,
           rows: 2,
           arrows: false,
        }
      },
      
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
           dots: true,
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });




$('.pxtabs-mob').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
           dots: true,
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });



  $('[data-bs-toggle="tooltip"]').tooltip();

  $('.pxtabs-links li a').click(function (e) {
    e.preventDefault();

    $('.pxtabs-item').addClass('hidden_animation visible animated fadeOutLeft')
    $('.pxtabs-links li a').removeClass('active')
    $(this).addClass('active')
    let activeItem = $($(this).attr('href'));


    setTimeout(function () {
      $('.pxtabs-item').removeClass('active fadeInRight')

      activeItem.removeClass('fadeOutLeft').addClass("hidden_animation visible animated fadeInRight")

      setTimeout(function () {
       activeItem.removeClass("hidden_animation")
      }, 500)

      activeItem.addClass('active')
    }, 500)




  })


  $(window).on("resize", function () {
      var maxH = 0;  
      $('.pxtools-item').each(function(){
           
          $(this).css('height','auto');
           
          //console.log($(this).height());
          if ( $(this).height() > maxH ) {
              maxH = $(this).height();
          }
           
      });
       
      $('.pxtools-item').height(maxH);
  }).resize();



  $('#section-table').viewportChecker({
      classToAdd: 'visible',
      repeat: true,
      classToAddForFullView: '',
      offset: '10%',
      // invertBottomOffset: true, 
      callbackFunction: function(elem, action){

        if (action == 'add' && ($(window).scrollTop()-$('#section-table').offset().top) > 0) {
         $('#section-table').addClass('full-visible') 
        }else{
         $('#section-table').removeClass('full-visible') 
        }

        if (action == 'add' && ($(window).scrollTop()-$('#section-table').offset().top) > Number($('#section-table').height())/2) {
          $(elem).find('#position-sticky').addClass('mb-10')
        }else{
          $(elem).find('#position-sticky').removeClass('mb-10')
        }
      },
    }); 


});


(function () {
  'use strict'

  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  var forms = document.querySelectorAll('.needs-validation')

  // Loop over them and prevent submission
  Array.prototype.slice.call(forms)
    .forEach(function (form) {
      form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
          console.log('error');


          form.classList.add('error')
        }
        if (form.checkValidity() && form.classList.contains('coupon-form')) {
          event.preventDefault()
          $('.coupon-reset').removeClass('d-none');
          $('.coupon-submit').addClass('d-none')

          $('input[name="code"]').attr('disabled', 'disabled')

          $('#coupon-price').text('$19.99')
          $('#full-price').removeClass('d-none')

        }

        if (form.checkValidity() && form.classList.contains('payment-form')) {
          event.preventDefault()
          /*valid card https://jquerycreditcardvalidator.com/*/
          $('#card').validateCreditCard(function (result) {
            console.table(result)

          });

        }



        form.classList.add('was-validated')
      }, false)

    })
})()